import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Flex, Box } from 'Components/UI'
import { CONTENT_WIDTH, CONTENT_PADDING_X } from 'Constants/ids'

export const Container = styled(Flex).attrs({
  flexDirection: 'column',
  alignItems: 'center',
})`
  width: 100%;
  background: ${themeGet('colors.pearl')};
`

export const Responsive = styled(Box).attrs({
  px: CONTENT_PADDING_X,
})`
  width: 100%;
  max-width: ${CONTENT_WIDTH}px;
`
