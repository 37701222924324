import React, { useMemo } from 'react'
import { NavLink } from 'react-router-dom'

import get from 'lodash/get'
import map from 'lodash/map'

import { PUBLIC_PATHS } from 'Constants/paths'
import { _ } from 'Services/I18n'
import { processBuildCategoryValue } from 'Services/Utils/merchants'

export default ({ categoryValue, categories, onNavLinkClick }) => {
  return useMemo(() => {
    const selectedCategoryTree = processBuildCategoryValue(
      categoryValue,
      categories,
    )
    const secondaryCategoryId = get(
      selectedCategoryTree,
      'value.category_2.bucket_id',
    )
    const secondaryCategoryName = get(
      selectedCategoryTree,
      'value.category_2.bucket_name',
    )

    const links = [
      { id: 0, label: _('common.home'), path: '/' },
      { id: 1, label: _('common.allCategories'), path: '/categories' },
    ]

    if (selectedCategoryTree) {
      links.push({
        id: get(selectedCategoryTree, 'value.bucket_id'),
        label: get(selectedCategoryTree, 'value.bucket_name'),
        path: PUBLIC_PATHS.PRIMARY_CATEGORY(
          get(selectedCategoryTree, 'value.bucket_slug'),
        ),
      })
    }

    if (secondaryCategoryId && secondaryCategoryName) {
      links.push({
        id: secondaryCategoryId,
        label: secondaryCategoryName,
      })
    }

    return map(links, link => {
      return {
        id: link.id,
        element: link.path ? (
          <NavLink
            activeStyle={{
              cursor: 'text',
              color: '#777777',
            }}
            exact
            key={link.id}
            to={{
              pathname: link.path,
            }}
            onClick={onNavLinkClick(link)}
          >
            {link.label}
          </NavLink>
        ) : (
          link.label
        ),
      }
    })
  }, [categoryValue])
}
