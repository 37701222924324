import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { renderRoutes } from 'react-router-config'

import get from 'lodash/get'
import size from 'lodash/size'

import { PUBLIC_PATHS } from 'Constants/paths'

import { Breadcrumbs, BannerCategories } from 'Components/Blocks'

import getBreadCrumbs from 'Services/Utils/getBreadCrumbs'

import { loadAllCategories } from 'Store/Actions/marketplace'

import { Container, Responsive } from './styles'

function Main({
  allCategories,
  isLoadingAllCategories,
  categoryValue,
  onLoadAllCategories,
  onSetCategoryValue,
  route,
  ssrPreloadedAllCategories,
}) {
  const categories = get(allCategories, 'categories', [])
  const pageSettings = get(allCategories, 'page_settings')

  const handleLoadAllCategories = () => {
    if (
      !size(categories) &&
      !isLoadingAllCategories &&
      !ssrPreloadedAllCategories
    ) {
      onLoadAllCategories({ ssr: false })
    }
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })

    handleLoadAllCategories()
  }, [])

  const onNavLinkClick = link => () => {
    if (link.path === PUBLIC_PATHS.CATEGORIES) {
      onSetCategoryValue(null)
    }
  }

  return (
    <Container>
      <BannerCategories pageSettings={pageSettings} />

      <Responsive>
        <Breadcrumbs
          links={getBreadCrumbs({
            categoryValue,
            categories,
            onNavLinkClick,
          })}
        />

        {renderRoutes(route.routes)}
      </Responsive>
    </Container>
  )
}

Main.fetchData = ({ dispatch }) => dispatch(loadAllCategories({ ssr: true }))

Main.defaultProps = {
  allCategories: {},
  categoryValue: null,
}

Main.propTypes = {
  allCategories: PropTypes.object,
  categoryValue: PropTypes.object,
  isLoadingAllCategories: PropTypes.bool.isRequired,
  route: PropTypes.object.isRequired,
  ssrPreloadedAllCategories: PropTypes.bool.isRequired,
  onLoadAllCategories: PropTypes.func.isRequired,
  onSetCategoryValue: PropTypes.func.isRequired,
}

export default Main
