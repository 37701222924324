import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { loadAllCategories, setCategoryValue } from 'Store/Actions/marketplace'

import {
  getAllCategories,
  getLoadingAllCategories,
  getCategoryValue,
  getSsrPreloadedAllCategories,
} from 'Store/Selectors/marketplace'

import Main from './Main'

const selector = createStructuredSelector({
  allCategories: getAllCategories,
  isLoadingAllCategories: getLoadingAllCategories,
  categoryValue: getCategoryValue,
  ssrPreloadedAllCategories: getSsrPreloadedAllCategories,
})

const actions = {
  onLoadAllCategories: loadAllCategories,
  onSetCategoryValue: setCategoryValue,
}

export default connect(selector, actions)(Main)
